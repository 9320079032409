import components_plugin_KR1HBZs4kY from "/opt/render/project/src/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/opt/render/project/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/opt/render/project/src/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_tbFNToZNim from "/opt/render/project/src/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_t2GMTTFnMT from "/opt/render/project/src/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_yVLowv6hDl from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import mode_client_2XIw9mTqDz from "/opt/render/project/src/plugins/mode.client.ts";
import splitting_client_o07XivwSC1 from "/opt/render/project/src/plugins/splitting.client.ts";
export default [
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  plugin_tbFNToZNim,
  plugin_t2GMTTFnMT,
  chunk_reload_client_UciE0i6zes,
  payload_client_yVLowv6hDl,
  mode_client_2XIw9mTqDz,
  splitting_client_o07XivwSC1
]