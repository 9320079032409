import * as THREE from 'three';
import useScene from '~/webgl/useScene';
import { getSizes } from '~/webgl/getSizes';
import usePerspectiveCamera from '~/webgl/usePerspectiveCamera';
import useRenderer from '~/webgl/useRenderer';
import useCursor from '~/webgl/useCursor';
import sleep from '~/common/sleep';
import { type ExperienceProjectsLight } from '~/webgl/webglExperienceProjects';
import useSmoothScrollSpeed from '~/webgl/useSmoothScrollSpeed';
import getBendShaderMaterial from '~/webgl/getBendShaderMaterial';

export interface ExperienceWorkRegular {
  init: () => void;
  start: (scrollContainer: HTMLElement) => void;
  end: () => void;
}

export interface ExperienceWorkLight {
}

export type ExperienceWork = null | ExperienceWorkRegular | ExperienceWorkLight

export function isExperienceWorkRegular (e: ExperienceWork): e is ExperienceWorkRegular {
  if (!e) {
    return false;
  }
  return 'init' in e;
}

export function webglExperienceWork (canvas: HTMLCanvasElement, isReducedMotion: boolean): Promise<ExperienceWorkRegular | ExperienceWorkLight> {
  if (isReducedMotion) {
    return animateLight();
  } else {
    return animateRegular();
  }

  function hideWorkCanvas () {
    canvas.style.visibility = 'hidden';
  }

  function showWorkCanvas () {
    canvas.style.visibility = 'unset';
  }

  async function animateLight (): Promise<ExperienceProjectsLight> {
    await sleep(50);
    return {};
  }

  async function animateRegular (): Promise<ExperienceWorkRegular> {
    let scrollContainer: HTMLElement | null = null;
    let isInitialised = false;
    let isRunning = false;
    let animationFrameId: number;
    const backgroundColor = 0x161616;
    const backgroundColorString = '#161616';
    const textureLoader = new THREE.TextureLoader();
    const scenes: THREE.Scene[] = [];
    const emptyTexture = await textureLoader.loadAsync('/images/_empty-texture.png');

    const clock = new THREE.Clock();
    const { scene, setSceneBackground, setAppTheme } = useScene('#161616');
    const sizes = getSizes();

    /* camera */
    const { camera, getWorldSizes, updateCamera } = usePerspectiveCamera(25, sizes, 200);
    scene.add(camera);

    /* renderer */
    const { renderer, updateRenderer } = useRenderer(canvas, sizes);
    renderer.outputEncoding = THREE.sRGBEncoding;

    /* scroll speed */
    const smoothScroll = useSmoothScrollSpeed({ target: window });

    /* shader material */
    const bendMaterial = getBendShaderMaterial();

    async function init () {
      if (isInitialised) {
        scenes.forEach((s) => {
          const newEL = document.querySelector(`[data-work][data-name="${s.userData.name}"]`);
          s.userData.element = newEL;
        });

        return;
      }

      const sceneElements = Array.from(document.querySelectorAll('[data-work]')) as HTMLElement[];
      for (let i = 0; i < sceneElements.length; i++) {
        const el = sceneElements[i];
        const scene = await getScene(el);
        scene.userData.updateRect();
        scene.userData.updateSizes();

        const { updateTick, updateOnMouseMove, updateOnResize, updateOnScroll } = draw(scene, i);

        scene.userData.updateTick = updateTick;
        scene.userData.updateOnMouseMove = updateOnMouseMove;
        scene.userData.updateOnResize = updateOnResize;
        scene.userData.updateOnScroll = updateOnScroll;

        scenes.push(scene);
      }

      isInitialised = true;
    }

    function start (sCt: HTMLElement) {
      scrollContainer = sCt;

      if (scrollContainer) {
        smoothScroll.setTarget(scrollContainer);

        scrollContainer.addEventListener('resize', onWindowResize);
        scrollContainer.addEventListener('mousemove', onWindowMouseMove);
        scrollContainer.addEventListener('scroll', onWindowScroll);

        isRunning = true;
        onWindowResize();

        animationFrameId = requestAnimationFrame(tick);
        showWorkCanvas();
      }
    }

    function end () {
      isRunning = false;
      if (scrollContainer) {
        scrollContainer.removeEventListener('resize', onWindowResize);
        scrollContainer.removeEventListener('mousemove', onWindowMouseMove);
        scrollContainer.removeEventListener('scroll', onWindowScroll);
      }
      scrollContainer = null;
      hideWorkCanvas();
      cancelAnimationFrame(animationFrameId);
    }

    return {
      init,
      start,
      end
    };

    /* methods and stuff */
    function tick () {
      const elapsedTime = clock.getElapsedTime();

      smoothScroll.updateSmoothScroll();

      scenes.forEach((scene, index) => {
        if (!isRunning) {
          return;
        }
        scene.userData.updateTick(elapsedTime);

        const rect = scene.userData.boundingRect;
        const width = rect.right - rect.left;
        const height = rect.bottom - rect.top;
        const left = rect.left;
        const bottom = canvas.clientHeight - rect.bottom + canvas.offsetTop;

        renderer.setViewport(left, bottom, width, height);
        renderer.setScissor(left, bottom, width, height);
        renderer.setScissorTest(true);
        renderer.setClearColor(backgroundColor, 0);
        renderer.render(scene, scene.userData.perspectiveCamera.camera);
      });

      animationFrameId = requestAnimationFrame(tick);
    }

    function draw (scene: THREE.Scene, index: number) {
      const worldSizes = scene.userData.perspectiveCamera.getWorldSizes();

      const material = bendMaterial.clone();

      const x = 0;
      const y = 0;
      const width = worldSizes.width;
      const height = worldSizes.height;
      const radius = 10;

      const shape = new THREE.Shape();
      shape.moveTo(x, y + radius);
      shape.lineTo(x, y + height - radius);
      shape.quadraticCurveTo(x, y + height, x + radius, y + height);
      shape.lineTo(x + width - radius, y + height);
      shape.quadraticCurveTo(x + width, y + height, x + width, y + height - radius);
      shape.lineTo(x + width, y + radius);
      shape.quadraticCurveTo(x + width, y, x + width - radius, y);
      shape.lineTo(x + radius, y);
      shape.quadraticCurveTo(x, y, x, y + radius);

      // const plane = new THREE.ShapeGeometry(shape);
      const plane = new THREE.PlaneGeometry(worldSizes.width, worldSizes.height, 32, 32);

      material.uniforms.uMeshSize.value.x = worldSizes.width;
      material.uniforms.uMeshSize.value.y = worldSizes.height;

      material.uniforms.uTexture.value = scene.userData.texture;

      material.uniforms.uImageSize.value.x = scene.userData.texture.source.data.naturalWidth;
      material.uniforms.uImageSize.value.y = scene.userData.texture.source.data.naturalHeight;

      const mesh = new THREE.Mesh(plane, material);
      mesh.position.set(0, 0, 0);
      scene.add(mesh);

      return {
        updateTick,
        updateOnMouseMove,
        updateOnResize,
        updateOnScroll
      };

      function updateTick (elapsedTime: number) {
        const offsetY = Math.max(-800, Math.min(800, -(smoothScroll.left.scroll - smoothScroll.left.smoothScroll)));

        material.uniforms.uOffset.value.set(offsetY * 0.0003, 0);
      }

      function updateOnMouseMove () {
        const newMouseX = scene.userData.cursor.cursor.x;
        const newMouseY = scene.userData.cursor.cursor.y;

        material.uniforms.uMouse.value.x = newMouseX;
        material.uniforms.uMouse.value.y = 1.0 - newMouseY;
      }

      function updateOnResize () {
      }

      function updateOnScroll (scrollLeft: number, windowProgress: number) {
      }
    }

    async function getScene (el: HTMLElement) {
      const { scene, setSceneBackground, setAppTheme } = useScene(backgroundColorString);
      // @ts-ignore
      scene.background = 'transparent';
      scene.userData.element = el;
      scene.userData.name = el.getAttribute('data-name');

      /* texture */
      // @ts-ignore
      const image = el.querySelector('[data-texture] .image') as HTMLImageElement | null;
      const imageSrc = image?.src ?? null;
      // const imageSrc = el.querySelector('[data-texture]')?.getAttribute('data-src');
      const texture = imageSrc ? await textureLoader.loadAsync(imageSrc) : emptyTexture;
      texture.minFilter = THREE.LinearMipMapLinearFilter;
      texture.generateMipmaps = false;
      scene.userData.texture = texture;

      /* rect */
      scene.userData.boundingRect = scene.userData.element.getBoundingClientRect();
      scene.userData.updateRect = updateRect;

      const sizes = {
        width: 0.5,
        height: 0.5 / scene.userData.boundingRect.width * scene.userData.boundingRect.height
      };

      /* camera */
      scene.userData.perspectiveCamera = usePerspectiveCamera(1, sizes, 50);
      scene.userData.updateSizes = updateSizes;

      /* cursor */
      scene.userData.cursor = useCursor();

      function updateSizes () {
        sizes.width = scene.userData.boundingRect.width;
        sizes.height = scene.userData.boundingRect.height;
        scene.userData.perspectiveCamera.updateCamera(sizes);
      }

      function updateRect () {
        scene.userData.boundingRect = scene.userData.element.getBoundingClientRect();
      }

      return scene;
    }

    /* event listeners */
    function onWindowResize () {
      scenes.forEach((s) => {
        s.userData.updateRect();
        s.userData.updateSizes();
        s.userData.updateOnResize();
      });
    }

    function onWindowMouseMove (e: MouseEvent) {
      scenes.forEach((scene) => {
        const eX = e.clientX - scene.userData.boundingRect.left;
        const eY = e.clientY - scene.userData.boundingRect.top;

        const cX = (1 / scene.userData.boundingRect.width * eX); // 0 to 1
        const cY = (1 / scene.userData.boundingRect.height * eY); // 0 to 1
        scene.userData.cursor.updateCursorPosition(cX, cY);
        scene.userData.updateOnMouseMove();
      });
    }

    function onWindowScroll (e: Event) {
      if (e.target && e.target instanceof HTMLElement) {
        // @ts-ignore
        const scrollLeft = Number(e.target.scrollLeft) ?? 0;
        const windowModulo = (scrollLeft % e.target.clientWidth) / e.target.clientWidth; // -> 0 - 1
        const windowProgress = Math.abs(windowModulo - 0.5) * 1;
        scenes.forEach((s) => {
          s.userData.updateRect();
          s.userData.updateSizes();
          s.userData.updateOnScroll(scrollLeft, windowProgress);
        });
      }
    }
  }
}
