import 'splitting/dist/splitting.css';
import 'splitting/dist/splitting-cells.css';
import Splitting from 'splitting';

export default defineNuxtPlugin((app) => {
  return {
    provide: {
      Splitting
    }
  };
});
