import * as THREE from 'three';

export default function useRenderer (canvas: HTMLCanvasElement, sizes: { width: number, height: number }) {
  const renderer = new THREE.WebGLRenderer({
    // antialias: true, // only set to true if it makes a difference (performance is better when turned off)
    alpha: true,
    premultipliedAlpha: false,
    canvas
    // powerPreference: 'high-performance' // only use that when having frame rate issues
  });
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

  function updateRenderer (newSizes: { width: number, height: number }) {
    renderer.setSize(newSizes.width, newSizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  }

  return {
    renderer,
    updateRenderer
  };
}
