import { injectionKey } from '~/common/inject';

export enum AppTheme {
  dark= 'dark',
  light= 'light',
}

export interface ThemeInjection {
  setTheme: (t: AppTheme) => void;
}

export const ThemeInjectionKey = injectionKey<ThemeInjection>();
export default function useTheme (): ThemeInjection {
  const currentAppTheme = ref<AppTheme>(AppTheme.dark);

  watchEffect(() => {
    if (typeof document !== 'undefined') {
      document.documentElement.setAttribute('data-theme', currentAppTheme.value);
    }
  });

  return {
    setTheme
  };

  function setTheme (t: AppTheme) {
    currentAppTheme.value = t;
  }
}
