<template>
  <a :href="`${siteUrl}#control-panel`" class="skip-link">Zur Navigation</a>
  <a :href="`${siteUrl}#contact`" class="skip-link">Zum Kontakt</a>
  <a :href="`${siteUrl}#projects`" class="skip-link">Zu den Projekten</a>
  <canvas
    ref="webglCanvas"
    class="webgl-container"
  />
  <canvas
    ref="webglCanvasProjects"
    class="webgl-container -projects"
  />
  <nav class="page-navigation">
    <ControlPanel id="control-panel" :key="locale" />
  </nav>
  <main class="page-main">
    <NuxtPage
      :key="locale"
      :experience="experience"
      :experience-projects="experienceProjects"
      :experience-work="experienceWork"
    />
  </main>
  <canvas
    ref="webglCanvasWork"
    class="webgl-container -work"
    :style="{ 'visibility': controlPanelInjection.isWork ? 'visible' : 'hidden'}"
  />
</template>

<script setup lang="ts">
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Observer } from 'gsap/Observer';
import { CustomEase } from 'gsap/CustomEase';
import { webglExperience, type Experience } from '~/webgl/webglExperience';
import { type ExperienceProjects } from '~/webgl/webglExperienceProjects';
import { statamicGlobals, StatamicGlobalsInjectionKey } from '~/content/statamicGlobals';
import { statamicLinks, StatamicLinksInjectionKey } from '~/content/statamicLinks';
import { statamicTeam, StatamicTeamInjectionKey } from '~/content/statamicTeam';
import useControlPanel, { ControlPanelInjectionKey } from '~/common/useControlPanel';
import { webglExperienceWork, type ExperienceWork } from '~/webgl/webglExperienceWork';
import useTheme, { ThemeInjectionKey } from '~/common/useTheme';
import ControlPanel from '~/common/ControlPanel.vue';
import useRandomOrderPerSession, { RandomOrderInjectionKey } from '~/common/useRandomOrderPerSession';
import statamicTheGridonicWay, { StatamicTheGridonicWayInjectionKey } from '~/content/statamicTheGridonicWay';
import statamicFooter, { StatamicFooterInjectionKey } from '~/content/statamicFooter';

gsap.registerPlugin(CustomEase);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(Observer);

const config = useRuntimeConfig();
const siteUrl = config.public.siteUrl;

const { locale, localeProperties } = useI18n();

const { $isInReducedMotion, $isReducedMotionPreferred, $isOnSlowNetwork } = useNuxtApp();

const webglCanvas = ref<null | HTMLCanvasElement>(null);
const webglCanvasProjects = ref<null | HTMLCanvasElement>(null);
const webglCanvasWork = ref<null | HTMLCanvasElement>(null);
const experience = ref<Experience>(null);
const experienceProjects = ref<ExperienceProjects>(null);
const experienceWork = ref<ExperienceWork>(null);
const isLoading = ref<boolean>(true);

const footerInjection = await statamicFooter();
provide(StatamicFooterInjectionKey, footerInjection);

const globalsInjection = await statamicGlobals();
provide(StatamicGlobalsInjectionKey, globalsInjection);

const theGridonicWayInjection = await statamicTheGridonicWay();
provide(StatamicTheGridonicWayInjectionKey, theGridonicWayInjection);

const teamInjection = await statamicTeam();
provide(StatamicTeamInjectionKey, teamInjection);

const controlPanelInjection = useControlPanel();
provide(ControlPanelInjectionKey, controlPanelInjection);

const links = await statamicLinks();
provide(StatamicLinksInjectionKey, links);

const theme = useTheme();
provide(ThemeInjectionKey, theme);

const randomOrder = useRandomOrderPerSession();
provide(RandomOrderInjectionKey, randomOrder);

onMounted(async () => {
  if (webglCanvas.value) {
    // experience.value = await webglExperience(webglCanvas.value, true);
    experience.value = await webglExperience(webglCanvas.value, $isReducedMotionPreferred || $isOnSlowNetwork);
    console.log('webgl experience loaded');
  }
  if (webglCanvasWork.value) {
    // todo defer loading this
    // experienceWork.value = await webglExperienceWork(webglCanvasWork.value, $isReducedMotionPreferred || $isOnSlowNetwork);
    experienceWork.value = await webglExperienceWork(webglCanvasWork.value, $isInReducedMotion as boolean);
    console.log('work experience loaded');
  }

  document.body.classList.add('-page-ready');
  document.body.classList.add('-ready');

  setTimeout(() => {
    isLoading.value = false;
  }, 50);
});

useHead({
  htmlAttrs: {
    dir: localeProperties.value.dir,
    lang: localeProperties.value.iso
  }
});

</script>

<style lang="scss">

body {
  &.-ready {
    .canvas-replacement {
      opacity: 1;
    }
  }

  &:not(.-ready) {
    max-height: 100vh;
    overflow-y: hidden;

    .canvas-replacement {
      opacity: 0;
    }
  }
}

.page-main {
  > .page {
    transition-duration: 0ms;
    max-width: 100vw;
    transform-origin: right center;
  }
}

</style>

<style scoped lang="scss">

.webgl-container {
  overflow: hidden;
  position: fixed;
  inset: 0;
  width: 100vw;
  height: var(--app-height);
  z-index: -1;
  outline: none;
  background: transparent;
  pointer-events: none;

  &.-work {
    z-index: 1;
  }
}

.page-navigation {
  z-index: 10;
  position: fixed;
  inset: auto 0 2rem 0;
  display: flex;
}

.experience-assets {
  visibility: hidden;
  height: 0;
  overflow: hidden;
}

</style>
