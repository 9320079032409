import { injectionKey } from '~/common/inject';
import useStatamicGlobalsApi from '~/content/useStatamicGlobalsApi';

export interface StatamicTheGridonicWayItem {
  title: string;
  description: string;
}

export interface StatamicTheGridonicWayDataGeneral {
  handle: 'the_gridonic_way';
  items: StatamicTheGridonicWayItem[];
}

export interface StatamicTheGridonicWayInjection {
  theGridonicWay: StatamicTheGridonicWayDataGeneral | null;
}

export const StatamicTheGridonicWayInjectionKey = injectionKey<StatamicTheGridonicWayInjection>();

export default async function statamicTheGridonicWay (): Promise<StatamicTheGridonicWayInjection> {
  const theGridonicWay = await useStatamicGlobalsApi<StatamicTheGridonicWayDataGeneral>({ path: 'the_gridonic_way' });

  return {
    theGridonicWay
  };
}
