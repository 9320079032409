<template>
  <div v-if="locale === 'en'" class="text-privacy" v-html="textPrivacyEN" />
  <div v-if="locale === 'de'" class="text-privacy" v-html="textPrivacyDE" />
</template>

<script setup lang="ts">
const { locale } = useI18n();

const textPrivacyDE = `
  <div class="text-privacy">
    <p class="subtitle">
      Zuletzt aktualisiert am 3. Mai 2023
    </p>
    <p>
      Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO),
      ist:
    </p>
    <p>
      Gridonic AG<br>Alexander Muischneek<br>Weststrasse 182<br>8003 Zürich
    </p>
    <p><a href="mailto:hello@gridonic.ch">hello@gridonic.ch</a></p>
    <p><a target="_blank" href="https://gridonic.ch/">www.gridonic.ch</a></p>
    <ol class="content">
      <li class="section">
        <p class="title">
          <b>Allgemeine Hinweise</b>
        </p>
        <p class="text">
          Gestützt auf Artikel 13 der schweizerischen Bundesverfassung und den datenschutzrechtlichen Bestimmungen des
          Bundes (Datenschutzgesetz, DSG) hat jede Person Anspruch auf Schutz ihrer Privatsphäre sowie auf Schutz vor
          Missbrauch ihrer persönlichen Daten. Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
          sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen
          Datenschutzvorschriften sowie dieser Datenschutzerklärung. Gemeinsam mit unseren Hosting-Providern (Cyon GmbH
          Schweiz und Netlify Inc, Dalaware USA) bemühen wir uns, die Datenbanken so gut wie möglich vor fremden
          Zugriffen, Verlusten, Missbrauch oder vor Fälschung zu schützen.
        </p>
        <p class="text">
          Durch die Nutzung dieser Website erklären Sie sich mit der Erhebung, Verarbeitung und Nutzung von Daten gemäss
          der nachfolgenden Beschreibung einverstanden. Diese Website kann grundsätzlich ohne Registrierung besucht
          werden. Dabei werden Daten wie beispielsweise aufgerufene Seiten bzw. Namen der abgerufenen Datei, Datum und
          Uhrzeit zu statistischen Zwecken auf dem Server gespeichert, ohne dass diese Daten unmittelbar auf Ihre Person
          bezogen werden. Personenbezogene Daten, insbesondere Name, Adresse oder E-Mail-Adresse werden soweit möglich
          auf freiwilliger Basis erhoben. Ohne Ihre Einwilligung erfolgt keine Weitergabe der Daten an Dritte.
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Bearbeitung von Personendaten</b>
        </p>
        <p class="text">
          Personendaten sind alle Angaben, die sich auf eine bestimmte oder bestimmbare Person beziehen. Eine betroffene
          Person ist eine Person, über die Personendaten bearbeitet werden. Bearbeiten umfasst jeden Umgang mit
          Personendaten, unabhängig von den angewandten Mitteln und Verfahren, insbesondere das Aufbewahren,
          Bekanntgeben, Beschaffen, Löschen, Speichern, Verändern, Vernichten und Verwenden von Personendaten. Wir
          bearbeiten Personendaten im Einklang mit dem schweizerischen Datenschutzrecht.
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Übermittlung von personenbezogenen Daten</b>
        </p>
        <p class="text">
          Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor, dass die Daten an andere Stellen,
          Unternehmen, rechtlich selbstständige Organisationseinheiten oder Personen übermittelt oder sie ihnen
          gegenüber
          offengelegt werden. Zu den Empfängern dieser Daten können z.B. mit IT-Aufgaben beauftragte Dienstleister oder
          Anbieter von Diensten und Inhalten, die in eine Webseite eingebunden werden, gehören. In solchen Fall beachten
          wir die gesetzlichen Vorgaben und schliessen insbesondere entsprechende Verträge bzw. Vereinbarungen, die dem
          Schutz Ihrer Daten dienen, mit den Empfängern Ihrer Daten ab.
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Datenschutzerklärung für Cookies</b>
        </p>
        <p class="text">
          Diese Website verwendet Cookies. Cookies sind Textdateien, die Daten von besuchten Websites oder Domains
          enthalten und von einem Browser auf dem Computer des Benutzers gespeichert werden. Ein Cookie dient in erster
          Linie dazu, die Informationen über einen Benutzer während oder nach seinem Besuch innerhalb eines
          Onlineangebotes zu speichern. Zu den gespeicherten Angaben können z.B. die Spracheinstellungen auf einer
          Webseite, der Loginstatus, ein Warenkorb oder die Stelle, an der ein Video geschaut wurde, gehören. Zu dem
          Begriff der Cookies zählen wir ferner andere Technologien, die die gleichen Funktionen wie Cookies erfüllen
          (z.B. wenn Angaben der Nutzer anhand pseudonymer Onlinekennzeichnungen gespeichert werden, auch als
          "Nutzer-IDs"
          bezeichnet).
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Die folgenden Cookie-Typen und Funktionen werden unterschieden:</b>
        </p>
        <p class="text">
          Temporäre Cookies (auch: Session- oder Sitzungs-Cookies): Temporäre Cookies werden spätestens gelöscht,
          nachdem
          ein Nutzer ein Online-Angebot verlassen und seinen Browser geschlossen hat. Permanente Cookies: Permanente
          Cookies bleiben auch nach dem Schliessen des Browsers gespeichert. So kann beispielsweise der Login-Status
          gespeichert oder bevorzugte Inhalte direkt angezeigt werden, wenn der Nutzer eine Website erneut besucht.
          Ebenso
          können die Interessen von Nutzern, die zur Reichweitenmessung oder zu Marketingzwecken verwendet werden, in
          einem solchen Cookie gespeichert werden. First-Party-Cookies: First-Party-Cookies werden von uns selbst
          gesetzt.
          Third-Party-Cookies (auch: Drittanbieter-Cookies): Drittanbieter-Cookies werden hauptsächlich von
          Werbetreibenden (sog. Dritten) verwendet, um Benutzerinformationen zu verarbeiten. Notwendige (auch:
          essenzielle
          oder unbedingt erforderliche) Cookies: Cookies können zum einen für den Betrieb einer Webseite unbedingt
          erforderlich sein (z.B. um Logins oder andere Nutzereingaben zu speichern oder aus Gründen der Sicherheit).
          Statistik-, Marketing- und Personalisierung-Cookies: Ferner werden Cookies im Regelfall auch im Rahmen der
          Reichweitenmessung eingesetzt sowie dann, wenn die Interessen eines Nutzers oder sein Verhalten (z.B.
          Betrachten
          bestimmter Inhalte, Nutzen von Funktionen etc.) auf einzelnen Webseiten in einem Nutzerprofil gespeichert
          werden. Solche Profile dienen dazu, den Nutzern z.B. Inhalte anzuzeigen, die ihren potenziellen Interessen
          entsprechen. Dieses Verfahren wird auch als "Tracking", d.h., Nachverfolgung der potenziellen Interessen der
          Nutzer bezeichnet. Soweit wir Cookies oder "Tracking"-Technologien einsetzen, informieren wir Sie gesondert in
          unserer Datenschutzerklärung.
        </p>
        <p class="text">
          Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung: Wir setzen ein Verfahren zum
          Cookie-Einwilligungs-Management ein, in dessen Rahmen die Einwilligungen der Nutzer in den Einsatz von
          Cookies,
          bzw. der im Rahmen des Cookie-Einwilligungs-Management-Verfahrens genannten Verarbeitungen und Anbieter
          eingeholt sowie von den Nutzern verwaltet und widerrufen werden können. Hierbei wird die
          Einwilligungserklärung
          gespeichert, um deren Abfrage nicht erneut wiederholen zu müssen und die Einwilligung entsprechend der
          gesetzlichen Verpflichtung nachweisen zu können. Die Speicherung kann serverseitig und/oder in einem Cookie
          (sogenanntes Opt-In-Cookie, bzw. mithilfe vergleichbarer Technologien) erfolgen, um die Einwilligung einem
          Nutzer, bzw. dessen Gerät zuordnen zu können. Vorbehaltlich individueller Angaben zu den Anbietern von
          Cookie-Management-Diensten gelten die folgenden Hinweise: Die Dauer der Speicherung der Einwilligung kann bis
          zu
          zwei Jahren betragen.
        </p>
        <p class="text">
          Verarbeitete Datenarten: Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
          Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen). Betroffene Personen: Nutzer (z.B.
          Webseitenbesucher, Nutzer von Onlinediensten).
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Datenschutzerklärung für SSL-/TLS-Verschlüsselung</b>
        </p>
        <p class="text">
          Diese Website nutzt aus Gründen der Sicherheit und zum Schutz der Übertragung vertraulicher Inhalte, wie zum
          Beispiel der Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-/TLS-Verschlüsselung. Eine
          verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von "http://" auf "https://"
          wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
        </p>
        <p class="text">
          Wenn die SSL bzw. TLS Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von
          Dritten mitgelesen werden.
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Datenschutzerklärung für Kontaktformular</b>
        </p>
        <p class="text">
          Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular
          inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
          Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Datenschutzerklärung für Newsletterdaten </b>
        </p>
        <p class="text">
          Wenn Sie den auf dieser Website angebotenen Newsletter beziehen möchten, benötigen wir von Ihnen eine
          E-Mail-Adresse sowie Informationen, welche uns die Überprüfung gestatten, dass Sie der Inhaber der angegebenen
          E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden sind. Weitere Daten werden nicht
          erhoben. Diese Daten verwenden wir ausschliesslich für den Versand der angeforderten Informationen und geben
          sie nicht an Dritte weiter.
        </p>
        <p class="text">
          Die erteilte Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des
          Newsletters können Sie jederzeit widerrufen, etwa über den «Austragen-Link» im Newsletter.
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Datenschutzerklärung für Google Analytics</b>
        </p>
        <p class="text">
          Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Ireland Limited. Wenn der
          Verantwortliche für die Datenverarbeitung auf dieser Website ausserhalb des Europäischen Wirtschaftsraumes
          oder der Schweiz sitzt, dann erfolgt die Google Analytics Datenverarbeitung durch Google LLC. Google LLC und
          Google Ireland Limited werden nachfolgend «Google» genannt.
        </p>
        <p class="text">
          Über die gewonnenen Statistiken können wir unser Angebot verbessern und für Sie als Nutzer interessanter
          ausgestalten. Diese Website verwendet Google Analytics zudem für eine geräteübergreifende Analyse von
          Besucherströmen, die über eine User-ID durchgeführt wird. Sofern Sie über ein Google-Benutzerkonto verfügen,
          können Sie in den dortigen Einstellungen unter «Meine Daten», «persönliche Daten» die geräteübergreifende
          Analyse Ihrer Nutzung deaktivieren.
        </p>
        <p class="text">
          Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von
          Google zusammengeführt. Wir weisen Sie darauf hin, dass auf dieser Website Google Analytics um den Code
          «_anonymizeIp();» erweitert wurde, um eine anonymisierte Erfassung von IP-Adressen zu gewährleisten. Dadurch
          werden IP-Adressen gekürzt weiterverarbeitet, eine Personenbeziehbarkeit kann damit ausgeschlossen werden.
          Soweit den über Sie erhobenen Daten ein Personenbezug zukommt, wird dieser also sofort ausgeschlossen und die
          personenbezogenen Daten damit umgehend gelöscht.
        </p>
        <p class="text">
          Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort
          gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung
          der Website auszuwerten, um Reports über die Websitenaktivitäten zusammenzustellen und um weitere mit der
          Websitennutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitenbetreiber zu
          erbringen.
        </p>
        <p class="text">
          Google Analytics verwendet Cookies. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser
          Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Sie können
          die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen
          Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website
          vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten
          und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung
          dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin
          herunterladen und installieren: Google Analytics deaktivieren.
        </p>
        <p class="text">
          Ausserdem können Sie die Nutzung von Google Analytics auch verhindern, indem sie auf diesen <a
            target="_blank"
            rel="external"
            href="https://www.google.com/settings"
          >Link</a> klicken: Google Analytics deaktivieren. Hierdurch wird ein sog.
          opt-out Cookie auf ihrem Datenträger gespeichert, der die Verarbeitung personenbezogener Daten durch Google
          Analytics verhindert. Bitte beachten Sie, dass bei einem Löschen sämtlicher Cookies auf Ihrem Endgerät auch
          diese Opt-out-Cookies gelöscht werden, d.h., dass Sie erneut die Opt-out-Cookies setzen müssen, wenn Sie
          weiterhin diese Form der Datenerhebung verhindern wollen. Die Opt-out-Cookies sind pro Browser und
          Rechner/Endgerät gesetzt und müssen daher für jeden Browser, Rechner oder anderes Endgerät gesondert aktiviert
          werden.
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Sie können die Teilnahme an diesem Tracking-Verfahren auf verschiedene Weise verhindern:</b>
        </p>
        <p class="text">
          durch eine entsprechende Einstellung Ihrer Browser-Software, insbesondere führt die Unterdrückung von
          Drittcookies dazu, dass Sie keine Anzeigen von Drittanbietern erhalten;<br>durch Deaktivierung der Cookies für
          Conversion-Tracking, indem Sie Ihren Browser so einstellen, dass Cookies von der Domain
          «www.googleadservices.com» blockiert werden, https://adssettings.google.com, wobei diese Einstellung gelöscht
          wird, wenn Sie Ihre Cookies löschen;<br>durch Deaktivierung der interessenbezogenen Anzeigen der Anbieter, die
          Teil der Selbstregulierungs-Kampagne «About Ads» sind, über den <a
            target="_blank"
            rel="external"
            href="https://optout.aboutads.info/"
          >Link</a>, wobei diese
          Einstellung gelöscht wird, wenn Sie Ihre Cookies löschen; durch dauerhafte Deaktivierung in Ihren Browsern
          Firefox, Internetexplorer oder Google Chrome unter dem Link. Wir weisen Sie darauf hin, dass Sie in diesem Fall
          gegebenenfalls nicht alle Funktionen dieses Angebots vollumfänglich nutzen können.
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Newsletter - Mailchimp</b>
        </p>
        <p class="text">
          Der Versand der Newsletter erfolgt mittels des Versanddienstleisters 'MailChimp', einer
          Newsletterversandplattform des US-Anbieters Rocket Science Group, LLC, 675 Ponce De Leon Ave NE #5000,
          Atlanta, GA 30308, USA. Die Datenschutzbestimmungen des Versanddienstleisters können Sie hier einsehen. The
          Rocket Science Group LLC d/b/a MailChimp ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet
          hierdurch eine Garantie, das europäisches Datenschutzniveau einzuhalten (PrivacyShield).
        </p>
        <p class="text">
          Der Versanddienstleister kann die Daten der Empfänger in pseudonymer Form, d.h. ohne Zuordnung zu einem
          Nutzer, zur Optimierung oder Verbesserung der eigenen Services nutzen, z.B. zur technischen Optimierung des
          Versandes und der Darstellung der Newsletter oder für statistische Zwecke verwenden. Der Versanddienstleister
          nutzt die Daten unserer Newsletterempfänger jedoch nicht, um diese selbst anzuschreiben oder um die Daten an
          Dritte weiterzugeben.
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Agenturdienstleistungen</b>
        </p>
        <p class="text">
          Wir verarbeiten die Daten unserer Kunden gem. den datenschutzrechtlichen Bestimmungen des Bundes
          (Datenschutzgesetz, DSG) und der EU-DSGVO im Rahmen unserer vertraglichen Leistungen.
        </p>
        <p class="text">
          Hierbei verarbeiten wir Bestandsdaten (z.B., Kundenstammdaten, wie Namen oder Adressen), Kontaktdaten (z.B.
          E-Mail, Telefonnummern), Inhaltsdaten (z.B. Texteingaben etc.), Vertragsdaten (z.B. Vertragsgegenstand,
          Laufzeit), Zahlungsdaten (z.B. Bankverbindung, Zahlungshistorie), Nutzungs- und Metadaten (z.B. im Rahmen der
          Auswertung und Erfolgsmessung von Marketingmassnahmen). Zu den Betroffenen gehören unsere Kunden,
          Interessenten sowie deren Kunden, Nutzer, Websitebesucher oder Mitarbeiter sowie Dritte. Der Zweck der
          Verarbeitung besteht in der Erbringung von Vertragsleistungen, Abrechnung und unserem Kundenservice. Wir
          verarbeiten Daten, die zur Begründung und Erfüllung der vertraglichen Leistungen erforderlich sind und weisen
          auf die Erforderlichkeit ihrer Angabe hin. Eine Offenlegung an Externe erfolgt nur, wenn sie im Rahmen eines
          Auftrags erforderlich ist. Bei der Verarbeitung der uns im Rahmen eines Auftrags überlassenen Daten handeln
          wir entsprechend den Weisungen der Auftraggeber sowie der gesetzlichen Vorgaben einer Auftragsverarbeitung
          gem. Art. 28 DSGVO und verarbeiten die Daten zu keinen anderen, als den auftragsgemässen Zwecken.
        </p>
        <p class="text">
          Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und vergleichbarer Pflichten. Die
          Erforderlichkeit der Aufbewahrung der Daten wird in unregelmässigen Abständen überprüft. Im Fall der
          gesetzlichen Archivierungspflichten erfolgt die Löschung nach deren Ablauf. Im Fall von Daten, die uns
          gegenüber im Rahmen eines Auftrags durch den Auftraggeber offengelegt wurden, löschen wir die Daten
          entsprechend den Vorgaben des Auftrags, grundsätzlich nach Ende des Auftrags.
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Hinweis zur Datenweitergabe in die USA</b>
        </p>
        <p class="text">
          Auf unserer Website sind unter anderem Tools von Unternehmen mit Sitz in den USA eingebunden. Wenn diese Tools
          aktiv sind, können Ihre personenbezogenen Daten an die US-Server der jeweiligen Unternehmen weitergegeben
          werden. Wir weisen darauf hin, dass die USA kein sicherer Drittstaat im Sinne des EU-Datenschutzrechts sind.
          US-Unternehmen sind dazu verpflichtet, personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne dass
          Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es kann daher nicht ausgeschlossen werden, dass
          US-Behörden (z.B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten,
          auswerten und dauerhaft speichern. Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Urheberrechte</b>
        </p>
        <p class="text">
          Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf der Website, gehören
          ausschliesslich dem Betreiber dieser Website oder den speziell genannten Rechteinhabern. Für die Reproduktion
          von sämtlichen Dateien, ist die schriftliche Zustimmung des Urheberrechtsträgers im Voraus einzuholen.
        </p>
        <p class="text">
          Wer ohne Einwilligung des jeweiligen Rechteinhabers eine Urheberrechtsverletzung begeht, kann sich strafbar
          und allenfalls schadenersatzpflichtig machen.
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Allgemeiner Haftungsausschluss</b>
        </p>
        <p class="text">
          Alle Angaben unseres Internetangebotes wurden sorgfältig geprüft. Wir bemühen uns, unser Informationsangebot
          aktuell, inhaltlich richtig und vollständig anzubieten. Trotzdem kann das Auftreten von Fehlern nicht völlig
          ausgeschlossen werden, womit wir keine Garantie für Vollständigkeit, Richtigkeit und Aktualität von
          Informationen auch journalistisch-redaktioneller Art übernehmen können. Haftungsansprüche aus Schäden
          materieller oder ideeller Art, die durch die Nutzung der angebotenen Informationen verursacht wurden, sind
          ausgeschlossen, sofern kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt.
        </p>
        <p class="text">
          Der Herausgeber kann nach eigenem Ermessen und ohne Ankündigung Texte verändern oder löschen und ist nicht
          verpflichtet, Inhalte dieser Website zu aktualisieren. Die Benutzung bzw. der Zugang zu dieser Website
          geschieht auf eigene Gefahr des Besuchers. Der Herausgeber, seine Auftraggeber oder Partner sind nicht
          verantwortlich für Schäden, wie direkte, indirekte, zufällige, vorab konkret zu bestimmende oder Folgeschäden,
          die angeblich durch den Besuch dieser Website entstanden sind und übernehmen hierfür folglich keine Haftung.
        </p>
        <p class="text">
          Der Herausgeber übernimmt ebenfalls keine Verantwortung und Haftung für die Inhalte und die Verfügbarkeit von
          Website Dritter, die über externe Links dieser Website erreichbar sind. Für den Inhalt der verlinkten Seiten
          sind ausschliesslich deren Betreiber verantwortlich. Der Herausgeber distanziert sich damit ausdrücklich von
          allen Inhalten Dritter, die möglicherweise straf- oder haftungsrechtlich relevant sind oder gegen die guten
          Sitten verstossen.
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Änderungen</b>
        </p>
        <p class="text">
          Wir können diese Datenschutzerklärung jederzeit ohne Vorankündigung anpassen. Es gilt die jeweils aktuelle,
          auf unserer Website publizierte Fassung. Soweit die Datenschutzerklärung Teil einer Vereinbarung mit Ihnen
          ist, werden wir Sie im Falle einer Aktualisierung über die Änderung per E-Mail oder auf andere geeignete Weise
          informieren.
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Fragen an den Datenschutzbeauftragten</b>
        </p>
        <p class="text">
          Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die
          für den Datenschutz zu Beginn der Datenschutzerklärung aufgeführten, verantwortlichen Person in unserer
          Organisation.
        </p>
      </li>
    </ol>
  </div>

`;

const textPrivacyEN = `
  <div class="text-privacy">
    <p class="subtitle">
      Last updated on 3 May 2023
    </p>
    <p>
      The controller within the meaning of data protection laws, in particular the EU General Data Protection Regulation (GDPR), is
    </p>
    <p>
      Gridonic AG<br>Alexander Muischneek<br>Weststrasse 182<br>8003 Zurich
    </p>
    <p><a href="mailto:hello@gridonic.ch">hello@gridonic.ch</a></p>
    <p><a target="_blank" href="https://gridonic.ch/en">www.gridonic.ch</a></p>
    <ol class="content">
      <li class="section">
        <p class="title">
          <b>General information</b>
        </p>
        <p class="text">
Based on Article 13 of the Swiss Federal Constitution and the data protection provisions of the Swiss Confederation (Data Protection Act, DSG), every person is entitled to protection of their privacy and protection against misuse of their personal data. The operators of these pages take the protection of your personal data very seriously. We treat your personal data confidentially and in accordance with the statutory data protection regulations and this privacy policy. Together with our hosting providers (Cyon GmbH Switzerland and Netlify Inc, Dalaware USA), we endeavour to protect the databases as well as possible against unauthorised access, loss, misuse or falsification.
        </p>
        <p class="text">
By using this website, you consent to the collection, processing and use of data in accordance with the following description. This website can generally be visited without registration. Data such as pages called up or the name of the file called up, date and time are stored on the server for statistical purposes without this data being directly related to your person. Personal data, in particular name, address or e-mail address, are collected on a voluntary basis as far as possible. No data will be passed on to third parties without your consent.
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Processing of personal data</b>
        </p>
        <p class="text">
Personal data is any information relating to an identified or identifiable person. A data subject is a person about whom personal data is processed. Processing includes any handling of personal data, regardless of the means and procedures used, in particular the storage, disclosure, procurement, erasure, storage, modification, destruction and use of personal data. We process personal data in accordance with Swiss data protection law.
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Transfer of personal datan</b>
        </p>
        <p class="text">
As part of our processing of personal data, data may be transferred to other bodies, companies, legally independent organisational units or persons or disclosed to them. The recipients of this data may include, for example, service providers commissioned with IT tasks or providers of services and content that are integrated into a website. In such cases, we observe the legal requirements and in particular conclude corresponding contracts or agreements with the recipients of your data that serve to protect your data.
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Privacy policy for cookies</b>
        </p>
        <p class="text">
    This website uses cookies. Cookies are text files that contain data from websites or domains visited and are stored by a browser on the user's computer. A cookie is primarily used to store information about a user during or after their visit to an online service. The information stored may include, for example, the language settings on a website, the login status, a shopping basket or the location where a video was watched. The term ‘cookies’ also includes other technologies that fulfil the same functions as cookies (e.g. when user information is stored using pseudonymous online identifiers, also known as ‘user IDs’).
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>A distinction is made between the following cookie types and functions:
</b>
        </p>
        <p class="text">
Temporary cookies (also: session cookies): Temporary cookies are deleted at the latest after a user has left an online service and closed their browser. Permanent cookies: Permanent cookies remain stored even after the browser is closed. For example, the login status can be saved or favourite content can be displayed directly when the user visits a website again. The interests of users that are used for reach measurement or marketing purposes can also be stored in such a cookie. First-party cookies: First-party cookies are set by us. Third-party cookies (also: third-party cookies): Third-party cookies are mainly used by advertisers (so-called third parties) to process user information. Necessary (also: essential or absolutely necessary) cookies: Cookies may be absolutely necessary for the operation of a website (e.g. to save logins or other user input or for security reasons).  Statistical, marketing and personalisation cookies: Cookies are also generally used to measure reach and when a user's interests or behaviour (e.g. viewing certain content, using functions, etc.) on individual websites are stored in a user profile. Such profiles are used, for example, to show users content that matches their potential interests. This process is also referred to as ‘tracking’, i.e. tracking the potential interests of users. If we use cookies or ‘tracking’ technologies, we will inform you separately in our privacy policy.
        </p>
        <p class="text">
Processing of cookie data on the basis of consent: We use a cookie consent management procedure in which the user's consent to the use of cookies or the processing and providers named in the cookie consent management procedure can be obtained, managed and revoked by the user. The declaration of consent is stored so that it does not have to be requested again and the consent can be proven in accordance with the legal obligation. Consent can be stored on the server and/or in a cookie (so-called opt-in cookie or with the help of comparable technologies) in order to be able to assign the consent to a user or their device. Subject to individual information on the providers of cookie management services, the following information applies: Consent may be stored for up to two years.
        </p>
        <p class="text">
Processed data types: Usage data (e.g. websites visited, interest in content, access times), meta/communication data (e.g. device information, IP addresses). Data subjects: Users (e.g. website visitors, users of online services).

        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Privacy policy for SSL/TLS encryption</b>
        </p>
        <p class="text">
This website uses SSL/TLS encryption for security reasons and to protect the transmission of confidential content, such as the enquiries you send to us as the site operator. You can recognise an encrypted connection by the fact that the address line of the browser changes from ‘http://’ to ‘https://’ and by the lock symbol in your browser line.

        </p>
        <p class="text">
If SSL or TLS encryption is activated, the data you transmit to us cannot be read by third parties.

        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Privacy policy for contact form</b>
        </p>
        <p class="text">
If you send us enquiries via the contact form, your details from the enquiry form, including the contact details you provide there, will be stored by us for the purpose of processing the enquiry and in the event of follow-up questions. We will not pass on this data without your consent.
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Privacy policy for newsletter data</b>
        </p>
        <p class="text">
If you would like to receive the newsletter offered on this website, we require an e-mail address from you as well as information that allows us to verify that you are the owner of the e-mail address provided and that you agree to receive the newsletter. No further data is collected. We use this data exclusively for sending the requested information and do not pass it on to third parties.

        </p>
        <p class="text">
You can revoke your consent to the storage of the data, the e-mail address and its use for sending the newsletter at any time, for example via the ‘unsubscribe link’ in the newsletter.

        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Privacy policy for Google Analytics
</b>
        </p>
        <p class="text">
This website uses Google Analytics, a web analytics service provided by Google Ireland Limited. If the controller responsible for data processing on this website is located outside the European Economic Area or Switzerland, Google Analytics data processing is carried out by Google LLC. Google LLC and Google Ireland Limited are hereinafter referred to as ‘Google’.
        </p>
        <p class="text">
 We can use the statistics obtained to improve our website and make it more interesting for you as a user. This website also uses Google Analytics for a cross-device analysis of visitor flows, which is carried out via a user ID. If you have a Google user account, you can deactivate the cross-device analysis of your usage in the settings there under ‘My data’, ‘Personal data’.
        </p>
        <p class="text">
    The IP address transmitted by your browser as part of Google Analytics will not be merged with other Google data. We would like to point out that on this website Google Analytics has been extended by the code ‘_anonymizeIp();’ to ensure anonymised collection of IP addresses. As a result, IP addresses are further processed in abbreviated form, so that they cannot be linked to a specific person. If the data collected about you is personally identifiable, it is immediately excluded and the personal data is deleted immediately.

        </p>
        <p class="text">
Only in exceptional cases will the full IP address be transmitted to a Google server in the USA and truncated there. Google will use this information on behalf of the operator of this website to analyse your use of the website, to compile reports on website activity and to provide the website operator with other services relating to website activity and internet usage.
        </p>
        <p class="text">
Google Analytics uses cookies. The information generated by the cookie about your use of this website is usually transferred to a Google server in the USA and stored there. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website. You can also prevent Google from collecting the data generated by the cookie and relating to your use of the website (including your IP address) and from processing this data by Google by downloading and installing the browser plug-in available at the following link: Deactivate Google Analytics.

        </p>
        <p class="text">
          You can also prevent the use of Google Analytics by clicking on this link: <a
            target="_blank"
            rel="external"
            href="https://www.google.com/settings"
          >Deactivate Google Analytics</a>. This will store an opt-out cookie on your data carrier, which prevents the processing of personal data by Google Analytics. Please note that if you delete all cookies on your end device, these opt-out cookies will also be deleted, i.e. you will have to set the opt-out cookies again if you want to continue to prevent this form of data collection. The opt-out cookies are set per browser and computer/end device and must therefore be activated separately for each browser, computer or other end device.
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>You can prevent participation in this tracking process in various ways:
</b>
        </p>
        <p class="text">
         by setting your browser software accordingly; in particular, the suppression of third-party cookies means that you will not receive any adverts from third-party providers;
by deactivating cookies for conversion tracking by setting your browser to block cookies from the domain ‘www.googleadservices.com’, https://adssettings.google.com, whereby this setting is deleted when you delete your cookies;
<br>by deactivating the interest-based ads of the providers that are part of the <a
            target="_blank"
            rel="external"
            href="https://optout.aboutads.info/"
          >‘About Ads’</a> self-regulation campaign via the link, whereby this setting is deleted when you delete your cookies; by permanently deactivating them in your Firefox, Internet Explorer or Google Chrome browsers under the link. We would like to point out that in this case you may not be able to use all the functions of this website to their full extent.
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Newsletter - Mailchimp</b>
        </p>
        <p class="text">
The newsletter is sent using the mailing service provider ‘MailChimp’, a newsletter mailing platform of the US provider Rocket Science Group, LLC, 675 Ponce De Leon Ave NE #5000, Atlanta, GA 30308, USA. You can view the privacy policy of the mailing service provider here. The Rocket Science Group LLC d/b/a MailChimp is certified under the Privacy Shield Agreement and thus offers a guarantee of compliance with the European level of data protection (PrivacyShield).
        </p>
        <p class="text">
   The mailing service provider may use the recipient's data in pseudonymous form, i.e. without assigning it to a user, to optimise or improve its own services, e.g. to technically optimise the mailing and presentation of the newsletter or for statistical purposes. However, the mailing service provider does not use the data of our newsletter recipients to write to them itself or to pass the data on to third parties.
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Agency services</b>
        </p>
        <p class="text">
We process the data of our customers in accordance with the data protection regulations of the federal government (Data Protection Act, DSG) and the EU GDPR as part of our contractual services.
        </p>
        <p class="text">
We process inventory data (e.g. customer master data, such as names or addresses), contact data (e.g. e-mail, telephone numbers), content data (e.g. text entries, etc.), contract data (e.g. subject matter of the contract, term), payment data (e.g. bank details, payment history), usage and metadata (e.g. as part of the evaluation and performance measurement of marketing measures). The data subjects include our customers, interested parties and their customers, users, website visitors or employees as well as third parties. The purpose of the processing is the provision of contractual services, billing and our customer service. We process data that is necessary for the justification and fulfilment of the contractual services and point out the necessity of its disclosure. Disclosure to external parties only takes place if it is necessary in the context of an order. When processing the data provided to us as part of an order, we act in accordance with the instructions of the client and the legal requirements of order processing in accordance with Art. 28 GDPR and do not process the data for any purposes other than those specified in the order.

        </p>
        <p class="text">
We delete the data after the expiry of statutory warranty and comparable obligations. The necessity of storing the data is reviewed at irregular intervals. In the case of statutory archiving obligations, the deletion takes place after their expiry. In the case of data disclosed to us by the client as part of an order, we delete the data in accordance with the specifications of the order, generally after the end of the order.

        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Note on data transfer to the USA</b>
        </p>
        <p class="text">
Our website includes tools from companies based in the USA. If these tools are active, your personal data may be transferred to the US servers of the respective companies. We would like to point out that the USA is not a safe third country within the meaning of EU data protection law. US companies are obliged to disclose personal data to security authorities without you as the data subject being able to take legal action against this. It can therefore not be ruled out that US authorities (e.g. secret services) may process, analyse and permanently store your data on US servers for surveillance purposes. We have no influence on these processing activities.
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Our website includes tools from companies based in the USA. If these tools are active, your personal data may be transferred to the US servers of the respective companies. We would like to point out that the USA is not a safe third country within the meaning of EU data protection law. US companies are obliged to disclose personal data to security authorities without you as the data subject being able to take legal action against this. It can therefore not be ruled out that US authorities (e.g. secret services) may process, analyse and permanently store your data on US servers for surveillance purposes. We have no influence on these processing activities.</b>
        </p>
        <p class="text">
The copyright and all other rights to content, images, photos or other files on the website belong exclusively to the operator of this website or the specifically named rights holders. The written consent of the copyright holder must be obtained in advance for the reproduction of any files.
        </p>
        <p class="text">
Anyone who commits a copyright infringement without the consent of the respective copyright holder may be liable to prosecution and may be liable for damages.
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>General disclaimer</b>
        </p>
        <p class="text">
All information on our website has been carefully checked. We endeavour to ensure that the information we provide is up-to-date, correct and complete. Nevertheless, the occurrence of errors cannot be completely ruled out, which means that we cannot guarantee the completeness, correctness and topicality of information, even of a journalistic or editorial nature. Liability claims arising from material or immaterial damage caused by the use of the information provided are excluded, unless there is evidence of wilful intent or gross negligence.
        </p>
        <p class="text">
The publisher may change or delete texts at its own discretion and without notice and is not obliged to update the content of this website. The use of or access to this website is at the visitor's own risk. The publisher, its clients or partners are not responsible for damages, such as direct, indirect, accidental, pre-determined or consequential damages, which are allegedly caused by visiting this website and therefore assume no liability for this.
        </p>
        <p class="text">
The publisher also accepts no responsibility or liability for the content and availability of third-party websites that can be accessed via external links on this website. The operators of the linked pages are solely responsible for their content. The publisher therefore expressly distances itself from all third-party content that may be relevant under criminal or liability law or offend common decency.
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Amendments</b>
        </p>
        <p class="text">
We may amend this privacy policy at any time without prior notice. The current version published on our website applies. If the data protection declaration is part of an agreement with you, we will inform you of the change by e-mail or other suitable means in the event of an update.
        </p>
      </li>
      <li class="section">
        <p class="title">
          <b>Questions for the data protection officer</b>
        </p>
        <p class="text">
If you have any questions about data protection, please send us an e-mail or contact the person responsible for data protection in our organisation listed at the beginning of this privacy policy directly.
        </p>
      </li>
    </ol>
  </div>

`;

</script>

<style  lang="scss">

.text-privacy {
  p {
    @include fluid(padding-bottom, 16);

    a {
      color: currentColor;
    }
  }

  > .content {
    list-style: none;
    counter-reset: counter;

    > .section {
      counter-increment: counter;

      > .title {
        padding-top: 1rem;

        &::before {
          content: counter(counter) ". ";
          font-weight: var(--font-weight-bold);
        }
      }
    }
  }
}
</style>
