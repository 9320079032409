import { injectionKey } from '~/common/inject';
import shuffleArray from '~/common/shuffleArray';

export interface RandomOrderInjection {
  indexes: Ref<Record<string, number[]>>;
  createIndex: (key: string, l: number) => void;
}

export const RandomOrderInjectionKey = injectionKey<RandomOrderInjection>();
export default function useRandomOrderPerSession () {
  const indexes = ref<Record<string, number[]>>({
    default: shuffleArray(createArray(25))
  });

  function createIndex (key: string, l: number) {
    indexes.value[key] = shuffleArray(createArray(l));
  }

  function createArray (l: number) {
    return Array.from(Array(l).keys());
  }

  return { indexes, createIndex };
}
