
interface StatamicApiOptions {
  path: string;
  key: string;
  queries?: string;
}

export default async function useStatamicCollectionsApi<T> (options: StatamicApiOptions): Promise<T[]> {
  const { locale } = useI18n();

  const config = useRuntimeConfig();
  let url = `${config.public.apiBaseUrl}collections/${options.path}/tree?site=${locale.value}`;

  if (options.queries) {
    url += `&${options.queries}`;
  }

  const hasPreview = config.public.previewMode === 'true';
  if (hasPreview) {
    url += '?preview=true';
  }

  const { data, error } = await useAsyncData(options.key, (): Promise<{ data: { page: T }[] }> => ($fetch(url)));

  if (error.value?.message) {
    console.error(`[collection] ${options.path} has error`, error.value.message);
  }

  const statamicData = data.value?.data ?? [];

  return statamicData.map(i => i.page);
}
