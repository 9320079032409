
interface StatamicGlobalsApiOptions {
  path: string;
}
export default async function useStatamicGlobalsApi<T> (options: StatamicGlobalsApiOptions): Promise<T|null> {
  const { locale } = useI18n();
  const config = useRuntimeConfig();
  let url = `${config.public.apiBaseUrl}globals/${options.path}?site=${locale.value}`;

  const hasPreview = config.public.previewMode === 'true';
  if (hasPreview) {
    url += '&preview=true';
  }

  const { data, error } = await useAsyncData(`globals-${options.path}`, (): Promise<{ data: T }> => ($fetch(url)));
  const statamicData = data.value?.data ?? null;

  if (error.value?.message) {
    console.error(`[globals] ${options.path} has error`, error.value.message);

    throw showError({ statusCode: 400, statusMessage: error.value.message });
  }

  return statamicData;
}
