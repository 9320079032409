import { injectionKey } from '~/common/inject';
import useStatamicGlobalsApi from '~/content/useStatamicGlobalsApi';

export interface StatamicGlobalsFooterAddress {
  city: string;
  title: string;
  map_link: string;
  street: string;
  zipcode: string;
  color: {
    value: 'blue' | 'green' | 'red' | 'yellow';
    key: 'blue' | 'green' | 'red' | 'yellow';
    label: string;
  };
}

export interface StatamicGlobalsFooter {
  handle: 'footer';
  address_set: StatamicGlobalsFooterAddress[];
}

export interface StatamicFooterInjection {
  footer: StatamicGlobalsFooter | null;
}

export const StatamicFooterInjectionKey = injectionKey<StatamicFooterInjection>();

export default async function statamicFooter (): Promise<StatamicFooterInjection> {
  const footer = await useStatamicGlobalsApi<StatamicGlobalsFooter>({ path: 'footer' });

  return {
    footer
  };
}
