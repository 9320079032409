<template>
  <button
    type="button"
    aria-label="Schliessen"
    class="close-x"
  >
    <svg
      class="svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="18" fill="var(--background)" />
      <path d="M12.75 23.25L23.25 12.75" stroke="var(--color)" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M23.25 23.25L12.75 12.75" stroke="var(--color)" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </button>
</template>

<script setup lang="ts">

</script>

<style lang="scss">

.close-x {
  @include fluid(--size, 28);

  --background: var(--control-panel--color-font);
  --color: var(--control-panel--color-background);

  width: var(--size);
  height: var(--size);
  background-color: transparent;
  border: none;
}

</style>
