export default function useCursor () {
  const cursor = {
    x: 0,
    y: 0
  };

  function updateCursorPosition (x: number, y: number) {
    cursor.x = x;
    cursor.y = y;
  }

  return {
    updateCursorPosition,
    cursor
  };
}
