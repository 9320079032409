import useStatamicCollectionsApi from '~/content/useStatamicCollectionsApi';
import { injectionKey } from '~/common/inject';
import {
  type StatamicBaseAsset
} from '~/content/StatamicTypes';

export interface StatamicProjectLink {
  project_teaser: {
    asset: StatamicBaseAsset[];
    type: 'teaser';
  }[];
  assets_home: {
    asset: StatamicBaseAsset[];
    type: 'asset_left' | 'asset_right';
  }[];
  short_title: string;
  slug: string;
  title: string;
  url: string;
  id: string;
}

export interface StatamicJobLink {
  slug: string;
  title: string;
  url: string;
  id: string;
}

export interface StatamicDeepDiveLink {
  slug: string;
  title: string;
  url: string;
  id: string;
}

export interface StatamicPageLink {
  slug: string;
  title: string;
  url: string;
  id: string;
}

export interface StatamicLinksInjection {
  links: {
    projects: StatamicProjectLink[];
    jobs: StatamicJobLink[];
    deepDives: StatamicDeepDiveLink[];
    pages: StatamicPageLink[];
  },
  findPageIDBySlug: (slug: string) => string;
}

export const StatamicLinksInjectionKey = injectionKey<StatamicLinksInjection>();

export async function statamicLinks (): Promise<StatamicLinksInjection> {
  const allPages = ref<(StatamicProjectLink | StatamicJobLink | StatamicDeepDiveLink | StatamicPageLink)[]>([]);

  const links: {
    projects: StatamicProjectLink[];
    jobs: StatamicJobLink[];
    deepDives: StatamicDeepDiveLink[];
    pages: StatamicPageLink[];
  } = {
    projects: [],
    jobs: [],
    deepDives: [],
    pages: []
  };

  const pJobs = useStatamicCollectionsApi<StatamicJobLink>({
    key: 'job-links',
    path: 'job_deep_dives',
    queries: 'fields=id,title,url,slug&max_depth=1'
  });

  const pProjects = useStatamicCollectionsApi<StatamicProjectLink>({
    key: 'project-links',
    path: 'project_deep_dives',
    queries: 'fields=id,title,url,slug,assets_home,project_teaser,short_title&max_depth=1'
  });

  const pDeepDives = useStatamicCollectionsApi<StatamicDeepDiveLink>({
    key: 'deep-dive-links',
    path: 'deep_dives',
    queries: 'fields=id,title,url,slug&max_depth=1'
  });

  const pPages = useStatamicCollectionsApi<StatamicPageLink>({
    key: 'page-links',
    path: 'pages',
    queries: 'fields=id,title,url,slug&max_depth=1'
  });

  await Promise.all([pJobs, pProjects, pDeepDives, pPages])
    .then((values) => {
      if (values && values.length > 0) {
        links.jobs = values[0];
        links.projects = values[1];
        links.deepDives = values[2];
        links.pages = values[3];

        allPages.value = [...values[0], ...values[1], ...values[2], ...values[3]];
      }
    });

  function findPageIDBySlug (slug: string) {
    if (slug === 'home') {
      return allPages.value.find(p => p.slug.includes(slug))?.id ?? '';
    }
    if (allPages.value.length) {
      return allPages.value.find(p => p.slug === slug)?.id ?? '';
    }

    return '';
  }

  return {
    links,
    findPageIDBySlug
  };
}
