<template>
  <div class="text-imprint">
    <div v-if="locale === 'en'" v-html="textEN" />
    <div v-if="locale === 'de'" v-html="textDE" />
    <p><a href="mailto:hello@gridonic.ch" target="_blank" rel="external">hello@gridonic.ch</a></p>
    <p>CHE-115.907.004</p>
  </div>
</template>

<script setup lang="ts">

const { locale } = useI18n();

const textDE = `
    <p>
      Gridonic AG <br>
      Weststrasse 182<br>
      8003 Zürich <br>
      Switzerland
    </p>
    <p>
      Niederlassung Bern <br>
      Genfergasse 8<br>
      3011 Bern
    </p>
`;

const textEN = `
    <p>
      Gridonic AG <br>
      Weststrasse 182<br>
      8003 Zürich <br>
      Switzerland
    </p>
    <p>
      Branch office Bern <br>
      Genfergasse 8<br>
      3011 Bern
    </p>
`;

</script>

<style scoped lang="scss">

.text-imprint {
  p {
    @include fluid(padding-bottom, 16);

    a {
      color: currentColor;
    }
  }
}
</style>
