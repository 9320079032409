import * as THREE from 'three';
import gsap from 'gsap';

export default function useScene (color = '#ffffff') {
  const scene = new THREE.Scene();
  scene.background = new THREE.Color(color);

  function setSceneBackground (rgb: number[]) {
    gsap.to(scene.background, {
      duration: 0.45,
      r: rgb[0] / 255,
      g: rgb[1] / 255,
      b: rgb[2] / 255
    });
  }

  function setAppTheme (theme: string) {
    // document.documentElement.setAttribute('data-theme', theme)
  }

  return {
    scene,
    setSceneBackground,
    setAppTheme
  };
}
