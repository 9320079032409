import { injectionKey } from '~/common/inject';
import {
  type StatamicBaseAsset
} from '~/content/StatamicTypes';
import useStatamicCollectionsApi from '~/content/useStatamicCollectionsApi';

export interface StatamicTeamMember {
  firstname: string;
  role: string;
  id: string;
  portrait_asset: StatamicBaseAsset[];
  slug: string;
  title: string;
  url: string;
}

export interface StatamicTeamInjection {
  teamMembers: StatamicTeamMember[]
}

export const StatamicTeamInjectionKey = injectionKey<StatamicTeamInjection>();

export async function statamicTeam (): Promise<StatamicTeamInjection> {
  const teamMembers = await useStatamicCollectionsApi<StatamicTeamMember>({
    key: 'team_members',
    path: 'team_members',
    queries: 'fields=id,firstname,role,title,url,slug,portrait_asset,portrait_size&max_depth=1'
  });

  return {
    teamMembers
  };
}
