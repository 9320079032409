export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"msapplication-TileColor","content":"#ffffff"},{"name":"theme-color","content":"#ffffff"}],"link":[{"rel":"apple-touch-icon","sizes":"180x180","href":"https://gridonic.ch/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"https://gridonic.ch/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"https://gridonic.ch/favicon-16x16.png"},{"rel":"manifest","href":"https://gridonic.ch/site.webmanifest"},{"rel":"mask-icon","color":"#141414","href":"https://gridonic.ch/safari-pinned-tab.svg"},{"rel":"icon","type":"image/svg+xml","href":"https://gridonic.ch/favicon.svg"},{"rel":"icon","type":"image/x-icon","href":"https://gridonic.ch/favicon.ico"}],"style":[],"script":[{"defer":true,"data-domain":"gridonic.ch","src":"https://plausible.io/js/script.tagged-events.js"},{"textContent":"\nvar _paq = window._paq = window._paq || [];\n_paq.push(['trackPageView']);\n_paq.push(['enableLinkTracking']);\n(function() {\nvar u=\"https://app.friendlyanalytics.ch/\";\n_paq.push(['setTrackerUrl', u+'js/tracker.php']);\n_paq.push(['setSiteId', '290']);\nvar d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];\ng.async=true; g.src=u+'js/tracker.php'; s.parentNode.insertBefore(g,s);\n})();\n"}],"noscript":[],"charset":"utf-8","viewport":"width=device-width, initial-scale=1"}

export const appPageTransition = {}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = false

export const devPagesDir = null

export const devRootDir = null