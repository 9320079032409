import gsap from 'gsap';

export default {
  onBeforeEnter: function (el: Element) {
    const { $isInReducedMotion } = useNuxtApp();

    if (!$isInReducedMotion) {
      gsap.set(el, {
        xPercent: 100,
        scale: 0.9
      });
    } else {
      gsap.set(el, {
        opacity: 0
      });
    }
  },
  onEnter: function (el: Element, done: () => void) {
    const { $isInReducedMotion } = useNuxtApp();

    const transitionTl = gsap.timeline({
      paused: true,
      onComplete: done
    });

    if (!$isInReducedMotion) {
      transitionTl
        .to(el, {
          xPercent: 0,
          duration: 0.85
        })
        .to(el, {
          scale: 1,
          duration: 0.5
        });
    } else {
      transitionTl
        .to(el, {
          opacity: 1,
          duration: 0.65
        });
    }
    transitionTl.play(0);

    return done();
  },
  onLeave: function (el: Element, done: () => void) {
    const { $isInReducedMotion } = useNuxtApp();
    if (!$isInReducedMotion) {
      if (el instanceof HTMLElement) {
        el.style.position = 'fixed';
        el.style.inset = '0';
        el.style.zIndex = '10';
      }
      const transitionTl = gsap.timeline({
        paused: true,
        onComplete: done
      });
      transitionTl
        .to(el, {
          scale: 0.9,
          duration: 0.5
        })
        .to(el, {
          xPercent: 100,
          duration: 0.85
        });
      transitionTl.play(0);
    } else {
      return done();
    }
  }
};
