import * as THREE from 'three';
import gsap from 'gsap';
import useRGBMLoader from '~/webgl/useRGBMLoader';
import { getLogo } from '~/webgl/getLogo';
import sleep from '~/common/sleep';
import { type ExperienceProjectsLight } from '~/webgl/webglExperienceProjects';
import useBaseScene from '~/webgl/useBaseScene';

export interface ExperienceRegular {
  logo: {
    container: THREE.Group;
    material: THREE.MeshStandardMaterial
  },
  gradients: {
    top: THREE.Object3D,
    bottom: THREE.Object3D,
    hide: (options?: { immediate: boolean }) => void;
    show: (options?: { immediate: boolean }) => void;
  },
  start: () => void;
  stop: () => void;
}

export interface ExperienceLight {

}

export type Experience = null | ExperienceRegular | ExperienceLight

export function isExperienceRegular (e: Experience): e is ExperienceRegular {
  if (!e) {
    return false;
  }
  return 'logo' in e;
}

export function webglExperience (canvas: HTMLCanvasElement, isReducedMotion: boolean): Promise<ExperienceRegular | ExperienceLight> {
  if (isReducedMotion) {
    return animateLight();
  } else {
    return animateRegular();
  }

  async function animateLight (): Promise<ExperienceProjectsLight> {
    await sleep(50);
    return {};
  }

  async function animateRegular (): Promise<ExperienceRegular> {
    const textureLoader = new THREE.TextureLoader();

    const base = useBaseScene(canvas, 25);
    base.setSceneBackground('#141414');
    base.renderer.physicallyCorrectLights = true;

    /* world sizes */
    const worldSizes = base.getWorldSizes();

    /* light */
    const lightAmbient = new THREE.AmbientLight(0xFFFFFF, 3);
    base.scene.add(lightAmbient);

    /* cubemaps */
    const {
      cubeRenderTarget: cubeRenderTargetCinemaBlue
    } = await useRGBMLoader(base.renderer, 'cubemap-cinema-blue-rotated');

    /* logo */
    const logoContainer = new THREE.Group();
    logoContainer.visible = false;
    base.scene.add(logoContainer);
    const { logo } = await getLogo(cubeRenderTargetCinemaBlue.texture);
    logoContainer.add(logo);

    /* background */
    const background = new THREE.Group();
    base.scene.add(background);

    const gradientTextureBlue = await textureLoader.loadAsync('/images/gradient-simple-blue.webp');
    const gradientTextureYellow = await textureLoader.loadAsync('/images/gradient-simple-yellow.webp');

    const gradientMaterial = new THREE.MeshStandardMaterial({
      color: 0xFFFFFF,
      map: null,
      transparent: true,
      opacity: 0,
      roughness: 0,
      metalness: 0
    });
    const gradientMaterials = {
      blue: gradientMaterial.clone(),
      yellow: gradientMaterial.clone()
    };
    gradientMaterials.blue.map = gradientTextureBlue;
    gradientMaterials.yellow.map = gradientTextureYellow;

    const vMax = worldSizes.width < worldSizes.height ? worldSizes.height : worldSizes.width;

    const gradientSizes = {
      width: vMax * 1,
      height: vMax * 1
    };

    const gradientScaleFactor = 1.4;

    const canvasCoordinates = {
      left: 0 - (worldSizes.width * 0.5),
      right: 0 + (worldSizes.width * 0.5),
      top: 0 + (worldSizes.height * 0.5),
      bottom: 0 - (worldSizes.height * 0.5)
    };

    const gradientGeometry = new THREE.PlaneGeometry(gradientSizes.width, gradientSizes.height);

    const gradientMesh1 = new THREE.Mesh(gradientGeometry, gradientMaterials.blue);
    gradientMesh1.position.set(canvasCoordinates.left, canvasCoordinates.top, -13);
    gradientMesh1.scale.set(gradientScaleFactor, gradientScaleFactor, gradientScaleFactor);
    background.add(gradientMesh1);
    gradientMesh1.userData.initialPosition = gradientMesh1.position;

    const gradientMesh2 = new THREE.Mesh(gradientGeometry, gradientMaterials.yellow);
    gradientMesh2.position.set(canvasCoordinates.right, canvasCoordinates.bottom, -13);
    gradientMesh2.scale.set(gradientScaleFactor, gradientScaleFactor, gradientScaleFactor);
    background.add(gradientMesh2);
    gradientMesh2.userData.initialPosition = gradientMesh2.position;

    const gradients = {
      top: gradientMesh1,
      bottom: gradientMesh2,
      hide: function (options?: { immediate: boolean }) {
        const immediate = options?.immediate === true;

        if (immediate) {
          this.bottom.material.visible = false;
          this.top.material.visible = false;
        }

        gsap.to(this.bottom.material, {
          opacity: 0,
          duration: 1.5
        });
        gsap.to(this.top.material, {
          opacity: 0,
          duration: 1.5
        });
      },
      show: function (options?: { immediate: boolean }) {
        const immediate = options?.immediate === true;

        this.bottom.material.visible = true;
        this.top.material.visible = true;

        gsap.to(this.bottom.material, {
          opacity: 1,
          duration: 4
        });
        gsap.to(this.top.material, {
          opacity: 1,
          duration: 4
        });
      }
    };

    /* tick */
    window.addEventListener('resize', onWindowResize);

    /* return */
    return {
      logo: {
        container: logoContainer,
        material: logo.material as THREE.MeshStandardMaterial
      },
      gradients,
      start: base.startTick,
      stop: base.stopTick
    };

    /* event listeners */
    function onWindowResize () {
      base.updateSizes();
    }
  }
}
