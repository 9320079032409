<template>
  <div class="jobs-list">
    <NuxtLink
      v-for="job in links.jobs"
      :key="job.id"
      class="link"
      :aria-label="`Job ${job.title}`"
      :to="localePath(`/jobs/${job.slug}`)"
    >
      {{ job.title }}
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">

import { injectStrict } from '~/common/inject';
import { StatamicLinksInjectionKey } from '~/content/statamicLinks';

const { links } = injectStrict(StatamicLinksInjectionKey);

const localePath = useLocalePath();

</script>

<style scoped lang="scss">

.jobs-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  > .link {
    @include text-xs;

    color: #ffffff;
    text-decoration: none;
  }
}

</style>
