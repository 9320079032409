export default function useSmoothScrollSpeed (settings: {
  target: HTMLElement | Window
}) {
  const ease = 0.075;

  const left = {
    scroll: 0,
    smoothScroll: 0
  };
  const top = {
    scroll: 0,
    smoothScroll: 0
  };

  function updateSmoothScroll () {
    if (settings.target instanceof HTMLElement) {
      top.scroll = settings.target.scrollTop;
      left.scroll = settings.target.scrollLeft;
    } else {
      top.scroll = settings.target.scrollY;
      left.scroll = settings.target.scrollX;
    }

    top.smoothScroll = lerp(top.smoothScroll, top.scroll, ease);
    left.smoothScroll = lerp(left.smoothScroll, left.scroll, ease);
  }

  function lerp (a: number, b: number, t: number): number {
    return (1 - t) * a + t * b;
  }

  function setTarget (t: HTMLElement | Window) {
    settings.target = t;
  }

  return { left, top, updateSmoothScroll, setTarget };
}
