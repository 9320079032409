import { type ComputedRef, type Ref } from 'vue';
import { injectionKey } from '~/common/inject';

export interface PopupLink {
  id: string;
  showInFooter: boolean;
  text: string;
}

export interface DeepDiveLink {
  title: string;
  route: string;
}

export interface ScrollLink {
  top: number;
}

type ControlPanelVisibilityOptions = '-faded-out' | '-faded-out-immediate' | '-faded-in' | '-faded-in-immediate'

interface ControlPanelInjection {
  isHome: ComputedRef<boolean>;
  isWork: ComputedRef<boolean>;
  isFooterView: Ref<boolean>;
  isOnFirstLevel: Ref<boolean>;
  isOnSecondLevel: Ref<boolean>;
  isOnThirdLevel: Ref<boolean>;
  extensionLink: Ref<(DeepDiveLink | ScrollLink | null)>;
  setControlPanelExtensionLink: (link: DeepDiveLink | ScrollLink | null) => void;
  goToLastVisitedRoute: () => void;
  toggleFooterView: (t: boolean) => void;
  deepDiveProgress: Ref<number>;
  visibility: Ref<ControlPanelVisibilityOptions>;
  setDeepDiveProgress: (value: number) => void;
  changeVisibility: (v: ControlPanelVisibilityOptions) => void;
  openPopup: (newPopupId: 'jobs' | 'imprint' | 'privacy' | null) => void;
  mouseLeavesPopup: () => void;
  currentPopup: Ref<PopupLink | null>;
}

export const popupLinks = [
  {
    id: 'imprint',
    showInFooter: true,
    text: 'controlPanel.imprint'
  },
  {
    id: 'privacy',
    showInFooter: true,
    text: 'controlPanel.privacy'
  },
  {
    id: 'jobs',
    showInFooter: false,
    text: 'controlPanel.jobs'
  }
];

export const internalLinks = [
  {
    showInFooter: false,
    link: '/team',
    text: 'controlPanel.team'
  },
  {
    showInFooter: false,
    link: '/work',
    text: 'controlPanel.work'
  }
];

export const ControlPanelInjectionKey = injectionKey<ControlPanelInjection>();

export default function useControlPanel (): ControlPanelInjection {
  const { isMobileOrTablet } = useDevice();

  const router = useRouter();
  const route = useRoute();

  /* current popup */
  const currentPopup = ref<PopupLink | null>(null);

  /* const's */
  const visibility = ref<ControlPanelVisibilityOptions>('-faded-out');
  const isFooterView = ref<boolean>(false);

  /* third level */
  const extensionLink = ref<DeepDiveLink | ScrollLink | null>(null);
  const deepDiveProgress = ref<number>(0);

  /* computed */
  const isHome = computed(() => {
    return route.path === '/' || route.path === '/en';
  });

  const isWork = computed(() => {
    return route.path === '/work' || route.path === '/en/work';
  });

  const lastVisitedMainRoute = ref<'home' | 'work'>('home');

  const isOnFirstLevel = ref<boolean>(false);
  const isOnSecondLevel = ref<boolean>(false);
  const isOnThirdLevel = ref<boolean>(false);

  /* watcher */
  watch(() => route.path, (toPath, fromPath) => {
    deepDiveProgress.value = 0;

    isOnFirstLevel.value = false;
    isOnSecondLevel.value = false;
    isOnThirdLevel.value = false;

    if (toPath === '/' || toPath === '/en' || toPath.includes('webagentur-schweiz') || toPath.includes('webapps')) {
      isOnFirstLevel.value = true;
      lastVisitedMainRoute.value = 'home';
    } else if (toPath === '/work' || toPath === '/en/work') {
      isOnSecondLevel.value = true;
      lastVisitedMainRoute.value = 'work';
    } else {
      isOnThirdLevel.value = true;
    }

    if (toPath !== '/' && toPath !== '/en') {
      extensionLink.value = null;
    }
  }, {
    immediate: true
  });

  watchEffect(() => {
    if (!isOnFirstLevel.value) {
      currentPopup.value = null;
      setControlPanelExtensionLink(null);
    }
  });

  watchEffect(() => {
    if (typeof window !== 'undefined' && isMobileOrTablet) {
      if (currentPopup.value !== null) {
        document.body.style.top = `-${window.scrollY}px`;
        document.body.style.position = 'fixed';
      } else {
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }
    }
  });

  /* methods */
  function setDeepDiveProgress (value: number) {
    deepDiveProgress.value = value;
  }

  function setControlPanelExtensionLink (link: DeepDiveLink | ScrollLink | null) {
    extensionLink.value = link;
  }

  function goToLastVisitedRoute () {
    isOnFirstLevel.value = false;
    isOnSecondLevel.value = false;
    isOnThirdLevel.value = false;

    if (lastVisitedMainRoute.value === 'home' || route.path === '/work') {
      router.push('/');
    } else {
      router.push('/work');
    }
  }

  function changeVisibility (v: ControlPanelVisibilityOptions) {
    visibility.value = v;
  }

  function toggleFooterView (toggle: boolean) {
    isFooterView.value = toggle;
  }

  function openPopup (newPopupId: 'jobs' | 'imprint' | 'privacy' | null) {
    const newPopup = newPopupId ? popupLinks.find(i => i.id === newPopupId) ?? null : null;

    if (newPopup && newPopup !== currentPopup.value) {
      currentPopup.value = newPopup;
    } else {
      currentPopup.value = null;
    }
  }

  function mouseLeavesPopup () {
    if (currentPopup.value !== null) {
      openPopup(null);
    }
  }

  return {
    visibility,
    isHome,
    isWork,
    isOnFirstLevel,
    isOnSecondLevel,
    isOnThirdLevel,
    extensionLink,
    deepDiveProgress,
    isFooterView,
    setControlPanelExtensionLink,
    toggleFooterView,
    goToLastVisitedRoute,
    setDeepDiveProgress,
    changeVisibility,
    openPopup,
    mouseLeavesPopup,
    currentPopup
  };
}
