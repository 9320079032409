import { default as _91id_93AFMBzgWxKLMeta } from "/opt/render/project/src/pages/[id].vue?macro=true";
import { default as indexYy8SidDWaAMeta } from "/opt/render/project/src/pages/index.vue?macro=true";
import { default as _91id_93aa32qm9dYQMeta } from "/opt/render/project/src/pages/jobs/[id].vue?macro=true";
import { default as _91id_93kyqPp0AenUMeta } from "/opt/render/project/src/pages/projects/[id].vue?macro=true";
import { default as webagentur_45schweiz9SY1gWn2QaMeta } from "/opt/render/project/src/pages/webagentur-schweiz.vue?macro=true";
import { default as webappsgpk5tmquK3Meta } from "/opt/render/project/src/pages/webapps.vue?macro=true";
import { default as workkIy1SRbp3FMeta } from "/opt/render/project/src/pages/work.vue?macro=true";
export default [
  {
    name: _91id_93AFMBzgWxKLMeta?.name ?? "id___de",
    path: _91id_93AFMBzgWxKLMeta?.path ?? "/:id()",
    meta: _91id_93AFMBzgWxKLMeta || {},
    alias: _91id_93AFMBzgWxKLMeta?.alias || [],
    redirect: _91id_93AFMBzgWxKLMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93AFMBzgWxKLMeta?.name ?? "id___en",
    path: _91id_93AFMBzgWxKLMeta?.path ?? "/en/:id()",
    meta: _91id_93AFMBzgWxKLMeta || {},
    alias: _91id_93AFMBzgWxKLMeta?.alias || [],
    redirect: _91id_93AFMBzgWxKLMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYy8SidDWaAMeta?.name ?? "index___de",
    path: indexYy8SidDWaAMeta?.path ?? "/",
    meta: indexYy8SidDWaAMeta || {},
    alias: indexYy8SidDWaAMeta?.alias || [],
    redirect: indexYy8SidDWaAMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexYy8SidDWaAMeta?.name ?? "index___en",
    path: indexYy8SidDWaAMeta?.path ?? "/en",
    meta: indexYy8SidDWaAMeta || {},
    alias: indexYy8SidDWaAMeta?.alias || [],
    redirect: indexYy8SidDWaAMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93aa32qm9dYQMeta?.name ?? "jobs-id___de",
    path: _91id_93aa32qm9dYQMeta?.path ?? "/jobs/:id()",
    meta: _91id_93aa32qm9dYQMeta || {},
    alias: _91id_93aa32qm9dYQMeta?.alias || [],
    redirect: _91id_93aa32qm9dYQMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/jobs/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93aa32qm9dYQMeta?.name ?? "jobs-id___en",
    path: _91id_93aa32qm9dYQMeta?.path ?? "/en/jobs/:id()",
    meta: _91id_93aa32qm9dYQMeta || {},
    alias: _91id_93aa32qm9dYQMeta?.alias || [],
    redirect: _91id_93aa32qm9dYQMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/jobs/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93kyqPp0AenUMeta?.name ?? "projects-id___de",
    path: _91id_93kyqPp0AenUMeta?.path ?? "/projects/:id()",
    meta: _91id_93kyqPp0AenUMeta || {},
    alias: _91id_93kyqPp0AenUMeta?.alias || [],
    redirect: _91id_93kyqPp0AenUMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/projects/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93kyqPp0AenUMeta?.name ?? "projects-id___en",
    path: _91id_93kyqPp0AenUMeta?.path ?? "/en/projects/:id()",
    meta: _91id_93kyqPp0AenUMeta || {},
    alias: _91id_93kyqPp0AenUMeta?.alias || [],
    redirect: _91id_93kyqPp0AenUMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/projects/[id].vue").then(m => m.default || m)
  },
  {
    name: webagentur_45schweiz9SY1gWn2QaMeta?.name ?? "webagentur-schweiz___de",
    path: webagentur_45schweiz9SY1gWn2QaMeta?.path ?? "/webagentur-schweiz",
    meta: webagentur_45schweiz9SY1gWn2QaMeta || {},
    alias: webagentur_45schweiz9SY1gWn2QaMeta?.alias || [],
    redirect: webagentur_45schweiz9SY1gWn2QaMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/webagentur-schweiz.vue").then(m => m.default || m)
  },
  {
    name: webagentur_45schweiz9SY1gWn2QaMeta?.name ?? "webagentur-schweiz___en",
    path: webagentur_45schweiz9SY1gWn2QaMeta?.path ?? "/en/webagentur-schweiz",
    meta: webagentur_45schweiz9SY1gWn2QaMeta || {},
    alias: webagentur_45schweiz9SY1gWn2QaMeta?.alias || [],
    redirect: webagentur_45schweiz9SY1gWn2QaMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/webagentur-schweiz.vue").then(m => m.default || m)
  },
  {
    name: webappsgpk5tmquK3Meta?.name ?? "webapps___de",
    path: webappsgpk5tmquK3Meta?.path ?? "/webapps",
    meta: webappsgpk5tmquK3Meta || {},
    alias: webappsgpk5tmquK3Meta?.alias || [],
    redirect: webappsgpk5tmquK3Meta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/webapps.vue").then(m => m.default || m)
  },
  {
    name: webappsgpk5tmquK3Meta?.name ?? "webapps___en",
    path: webappsgpk5tmquK3Meta?.path ?? "/en/webapps",
    meta: webappsgpk5tmquK3Meta || {},
    alias: webappsgpk5tmquK3Meta?.alias || [],
    redirect: webappsgpk5tmquK3Meta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/webapps.vue").then(m => m.default || m)
  },
  {
    name: workkIy1SRbp3FMeta?.name ?? "work___de",
    path: workkIy1SRbp3FMeta?.path ?? "/work",
    meta: workkIy1SRbp3FMeta || {},
    alias: workkIy1SRbp3FMeta?.alias || [],
    redirect: workkIy1SRbp3FMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/work.vue").then(m => m.default || m)
  },
  {
    name: workkIy1SRbp3FMeta?.name ?? "work___en",
    path: workkIy1SRbp3FMeta?.path ?? "/en/work",
    meta: workkIy1SRbp3FMeta || {},
    alias: workkIy1SRbp3FMeta?.alias || [],
    redirect: workkIy1SRbp3FMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/work.vue").then(m => m.default || m)
  }
]