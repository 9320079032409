<template>
  <svg
    tabindex="0"
    class="arrow-right"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="36" height="36" rx="18" fill="var(--background)" />
    <path d="M12 18H24" stroke="var(--color)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M18 24L24 18L18 12" stroke="var(--color)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script setup lang="ts">

</script>

<style lang="scss">

.arrow-right {
  @include fluid(--size, 28);

  --background: var(--control-panel--color-font);
  --color: var(--control-panel--color-background);

  width: var(--size);
  height: var(--size);

}

</style>
