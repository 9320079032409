export default defineNuxtPlugin((app) => {
  useAppHeight();

  const slowNetwork = isSlowNetwork();
  const reducedMotionMode = isSmallDevice() || isTouchDevice() || prefersReducedMotion() || slowNetwork;
  const isReducedMotionPreferred: boolean = prefersReducedMotion();

  if (reducedMotionMode) {
    document.documentElement.classList.add('-reduced-motion');
  } else {
    document.documentElement.classList.add('-full-motion');
  }

  if (slowNetwork) {
    document.documentElement.classList.add('-slow-network');
  } else {
    document.documentElement.classList.add('-fast-network');
  }

  if (isReducedMotionPreferred) {
    document.documentElement.classList.add('-reduced-motion-preferred');
  }

  return {
    provide: {
      isInReducedMotion: reducedMotionMode,
      isOnSlowNetwork: slowNetwork,
      isReducedMotionPreferred
    }
  };
});

function isTouchDevice () {
  return 'ontouchstart' in window || navigator.maxTouchPoints;
}

function prefersReducedMotion () {
  return window.matchMedia('(prefers-reduced-motion: reduce)').matches;
}

function isSmallDevice () {
  return window.matchMedia('(max-width: 800px)').matches;
}

// function isSlowNetwork () {
//   // @ts-ignore
//   const connection = navigator ? navigator.connection || navigator.mozConnection || navigator.webkitConnection : false;
//
//   if (connection && connection.effectiveType) {
//     return ['slow-2g', '2g', '3g'].includes(connection.effectiveType);
//   }
//   return false;
// }

function isSlowNetwork () {
  if ('connection' in navigator) {
    // @ts-ignore
    const connection = navigator.connection;

    if ('rtt' in connection && connection.rtt > 1000) {
      return true; // Network is slow
    }

    if ('downlink' in connection && connection.downlink < 1) {
      return true; // Network is slow
    }

    if ('effectiveType' in connection && connection.effectiveType) {
      return ['slow-2g', '2g', '3g'].includes(connection.effectiveType);
    }
  }

  // todo assume slow network on mobile?

  return false;
}

function useAppHeight () {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);

  window.addEventListener('resize', useAppHeight);
}
