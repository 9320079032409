import { injectionKey } from '~/common/inject';
import {
  type StatamicAsset
} from '~/content/StatamicTypes';
import useStatamicGlobalsApi from '~/content/useStatamicGlobalsApi';

export interface StatamicGlobalsDataGeneral {
  canvas_placeholder: StatamicAsset;
  to_home_page: string;
  handle: 'general';
}

export interface StatamicGlobalsInjection {
  general: StatamicGlobalsDataGeneral|null;
}

export const StatamicGlobalsInjectionKey = injectionKey<StatamicGlobalsInjection>();

export async function statamicGlobals (): Promise<StatamicGlobalsInjection> {
  const general = await useStatamicGlobalsApi<StatamicGlobalsDataGeneral>({ path: 'general' });

  return {
    general
  };
}
