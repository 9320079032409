import * as THREE from 'three';
import { useGltf } from '~/webgl/useGltf';

export async function getLogo (envMap: THREE.Texture) {
  const material = new THREE.MeshStandardMaterial({
    // side: THREE.DoubleSide,
    transparent: true,
    envMap,
    color: new THREE.Color('#000000'),
    metalness: 1,
    roughness: 0.5,
    envMapIntensity: 1,
    opacity: 0.7
  });

  const logoGlb = await useGltf('/3d-models/gridonic-logo.glb') as THREE.Group;
  const logoMesh = logoGlb.children[0] as THREE.Mesh;
  logoMesh.material = material;
  logoMesh.scale.set(30, 30, 30);

  return {
    logo: logoMesh
  };
}
